import React from "react";
import AdminDeptLayout from "../../layout/AdminiDeptLayout";

const Library = () => {
  const positions = [
    [
      {
        name: "Daw Sandar",
        position: "Head of Department",
        department: "Library",
      },
    ],
    [
      {
        name: "Daw Khin Htwe Myint",
        position: "Library Assistant (1)",
        department: "Library",
      },
      {
        name: "Daw Zin Mar Aye",
        position: "Library Assistant (3)",
        department: "Library",
      },
      {
        name: "Daw Myint Myint Aye",
        position: "Library Assistant (3)",
        department: "Library",
      },
    ],
    [
      {
        name: "Daw Nyo Nyo Htay",
        position: "Store Keeper (3)",
        department: "Library",
      },
      {
        name: "U Ar Kar Htet",
        position: "Store Keeper (4)",
        department: "Library",
      },
    ],
    [
      {
        name: "Daw Thi Thi Hlaing",
        position: "Accountant (4)",
        department: "Library",
      },
    ],
    [
      {
        name: "Daw Thin Thin Yu",
        position: "Branch Clerk",
        department: "Library",
      },
      {
        name: "Daw Nyo Nyo Htay",
        position: "Upper Division Clerk",
        department: "Library",
      },
      {
        name: "Daw Kay Khaing Nyein Tun",
        position: "Lower Division Clerk",
        department: "Library",
      },
    ],
  ];

  return <AdminDeptLayout title="Library" positions={positions} />;
};

export default Library;
