import React from "react";
import AdminDeptLayout from "../../layout/AdminiDeptLayout";

const FinanceDepartment = () => {
  const positions = [
    [
      {
        name: "Daw Khat Khat Oo",
        position: "Head",
        department: "Department of Finance & Finance",
      },
    ],
    [
      {
        name: "U San Nyunt",
        position: "Head of Division ",
        department: "Department of Finance",
      },
    ],
    [
      {
        name: "Daw Moe Moe Thin",
        position: "Head of Section",
        department: "Department of Finance",
      },
    ],
    [
      {
        name: "Daw Ywat War Nyein",
        position: "Accountant I",
        department: "Department of Finance",
      },
      {
        name: "Daw Zin Mar Win",
        position: "Accountant I",
        department: "Department of Finance",
      },
      {
        name: "U Aung San",
        position: "Accountant I",
        department: "Department of Finance",
      },
    ],
    [
      {
        name: "Daw Pa Pa Khaing",
        position: "Accountant II",
        department: "Department of Finance",
      },
      {
        name: "Daw Cherry Moe",
        position: "Accountant II",
        department: "Department of Finance",
      },
    ],
    [
      {
        name: "U Kaung Thant Zin",
        position: "Accountant III",
        department: "Department of Finance",
      },
      {
        name: "Daw Ei Ei Phyo",
        position: "Accountant III",
        department: "Department of Finance",
      },
      {
        name: "Daw Phyu Phyu Thin",
        position: "Accountant III",
        department: "Department of Finance",
      },
      {
        name: "Daw Ei Ei Zaw",
        position: "Accountant III",
        department: "Department of Finance",
      },
      {
        name: "U Chit Phyo Wai",
        position: "Accountant III",
        department: "Department of Finance",
      },
    ],
    [
      {
        name: "Daw Yu Mon Thet",
        position: "Accountant IV",
        department: "Department of Finance",
      },
      {
        name: "Daw Hnin Set Wai",
        position: "Accountant IV",
        department: "Department of Finance",
      },
      {
        name: "U Soe Htet Aung",
        position: "Accountant IV",
        department: "Department of Finance",
      },
      {
        name: "Daw Ei Shwe Sin Win ",
        position: "Accountant IV",
        department: "Department of Finance",
      },
    ],
    [
      {
        name: "Daw Khin Chaw Su",
        position: "Storekeeper II",
        department: "Department of Finance",
      },
    ],
    [
      {
        name: "U Zaw Min Oo",
        position: "Branch Clerk",
        department: "Department of Finance",
      },
    ],
    [
      {
        name: "U Ye Min Htay",
        position: "Senior Clerk ",
        department: "Department of Finance",
      },
    ],
  ];

  return <AdminDeptLayout title="Finance" positions={positions} />;
};

export default FinanceDepartment;
