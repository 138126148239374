import React from "react";
import { Flex, Image } from "../ui";
import "./index.css";
import AdministrativeDeptProfile from "../cards/AdministrativeDeptProfile";
import { siteLogo } from "../assets";

const AdminDeptLayout = ({ title, positions }) => {
  return (
    <Flex direction="col" className="h-auto md:pt-10">
      <Image src={siteLogo} height="160px" width="auto" />
      <h1 className="text-2xl my-5 font-semibold">
        Department of {" " + title}
      </h1>
      {positions.map((group, idx) => (
        <Flex
          key={idx}
          direction="row"
          justify={group.length > 2 ? "start" : "center"}
          align="center"
          className=" overflow-x-scroll max-w-[100vw] md:px-32  gap-8  mb-6"
          style={{ scrollbarWidth: "none" }}>
          {group.map((faculty, subIdx) => (
            <div
              className="min-w-1/3 max-w-1/2  "
              style={{ scrollbarWidth: "none" }}>
              <AdministrativeDeptProfile
                key={subIdx}
                name={faculty.name}
                position={faculty.position}
                department={faculty.department}
              />
            </div>
          ))}
        </Flex>
      ))}
    </Flex>
  );
};

export default AdminDeptLayout;
