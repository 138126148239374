import { DataRequire } from "../components";

function MasterPlan() {
  return (
    <>
      {/* <DataRequire /> */}

      <DataRequire />
    </>
  );
}

export default MasterPlan;
