import React from "react";
import AdminDeptLayout from "../../layout/AdminiDeptLayout";

const ICTDepartment = () => {
  const positions = [
    [
      {
        name: "U Ko Myat",
        position: "Head",
        department: "Department of ICT",
      },
    ],
    [
      {
        name: "U Khant Zin Thaw",
        position: "Upper Division Clerk",
        department: "Department of ICT",
      },
      {
        name: "U Eant Bwe Tun",
        position: "Upper Division Clerk",
        department: "Department of ICT",
      },
      {
        name: "U Phyoe Myat",
        position: "Upper Division Clerk",
        department: "Department of ICT",
      },
    ],
  ];

  return <AdminDeptLayout title="ICT" positions={positions} />;
};

export default ICTDepartment;
