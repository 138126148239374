import { FaChevronRight } from "react-icons/fa";
import { Flex, Icon, Text, Image } from "../ui";
import { IoChevronDownSharp } from "react-icons/io5";
import { siteLogo } from "../assets";
import { useNavigate } from "react-router-dom";
import { items } from "./Items";
import { useEffect, useRef, useState } from "react";
import "./index.css";
export const Redirects = ({ setMobileMenuOpen, setIsSearchInputOpen }) => {
  const [activeDropdown, setActiveDropDown] = useState(null);
  const [activeSubDropdown, setActiveSubDropDown] = useState(null);
  const [activeGrandchildrenDropdown, setActiveGrandchildrenDropDown] =
    useState(null);
  const dropdownRef = useRef(null);
  const Navigate = useNavigate();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropDown(null);
        setActiveSubDropDown(null);
        setActiveGrandchildrenDropDown(null);
        setMobileMenuOpen(false);
        setIsSearchInputOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setMobileMenuOpen, setIsSearchInputOpen]);

  const toggleRedirected = () => {
    setActiveDropDown(null);
    setActiveSubDropDown(null);
    setActiveGrandchildrenDropDown(null);
    setMobileMenuOpen(false);
  };
  return (
    <>
      <nav
        ref={dropdownRef}
        className="w-2/3  h-full md:h-auto md:max-h-12  p-5 md:p-0 md:w-full   fixed left-0 flex-col flex-wrap z-50  whitespace-normal justify-start items-start"
        style={{ backgroundColor: "rgba(41, 74, 112, 1)" }}>
        {" "}
        <div className="flex md:hidden flex-row justify-start items-center gap-2 ">
          {" "}
          <Image src={siteLogo} height="50px" alt="site logo" width="auto" />
          <Text
            content="Monywa University of Economics"
            color="rgba(255, 215, 0, 1)"
            size="12px"
            weight="500"
            backgroundColor="transparent"
          />
        </div>
        <Flex
          direction="col"
          justify="start"
          align="start"
          className="max-w-96 md:justify-evenly md:pl-32 md:pr-10 md:max-w-full md:flex-row">
          {items.map((item) => (
            <Flex
              direction="col"
              justify="start"
              align="start"
              className="cursor-pointer min-w-36"
              key={item.key}>
              <a
                href={item.route ? item.route : null}
                target={item.target && "_blank"}
                rel="noreferrer">
                <button
                  className="flex  flex-row justify-start text-base mt-3 mb-3 w-full items-center"
                  onClick={() => {
                    setActiveDropDown(
                      activeDropdown === item.key ? null : item.key
                    );
                    setActiveSubDropDown(null);
                    setActiveGrandchildrenDropDown(null);
                    item.route && toggleRedirected();
                  }}>
                  <Text
                    size="14px"
                    color="white"
                    content={item.label}
                    backgroundColor="transparent"
                    weight="400"
                    className="text-white whitespace-nowrap overflow-hidden"
                  />
                  {item.children && (
                    <Icon
                      IconComponent={IoChevronDownSharp}
                      size="14px"
                      color="white"
                      className="ml-2"
                    />
                  )}
                </button>
              </a>

              <div className=" flex flex-col  rounded-md">
                {" "}
                {item.children &&
                  item.key === activeDropdown &&
                  item.children.map((dropdown) => (
                    <>
                      <Flex
                        direction="col"
                        justify="start"
                        align="start"
                        className={`cursor-pointer  rounded-md min-w-52  ml-2 md:flex-row shadow−[0px 0px 6px 0px]  text-black ${dropdown.key}`}
                        key={dropdown.key}>
                        <button
                          className={`flex flex-row w-full p-2 justify-between min-w-[274px] max-w-[275px] items-center bg-white h-8 ${
                            (dropdown.key === "administrativeDepartment" ||
                              dropdown.key === "degreeOffered" ||
                              dropdown.key === "project" ||
                              dropdown.key === "master" ||
                              dropdown.key === "masterAdmission" ||
                              dropdown.key === "2025") &&
                            "rounded-b-lg"
                          }`}
                          onClick={() => {
                            setActiveSubDropDown(
                              activeSubDropdown === dropdown.key
                                ? null
                                : dropdown.key
                            );
                            setActiveGrandchildrenDropDown(null);

                            dropdown.route && Navigate(dropdown.route);
                            dropdown.route && toggleRedirected();
                          }}
                          style={{
                            backgroundColor: `${
                              activeSubDropdown === dropdown.key
                                ? "rgba(41, 74, 112, 1)"
                                : "white"
                            }`,
                            boxShadow: `${
                              activeSubDropdown === dropdown.key
                                ? "0px 0px 6px 0px"
                                : "0px 2px 4px 0px rgba(41, 74, 112, 1)"
                            }`,
                          }}>
                          <Text
                            size="14px"
                            color={`${
                              activeSubDropdown === dropdown.key
                                ? "white"
                                : "black"
                            }`}
                            content={dropdown.label}
                            weight="400"
                            backgroundColor="transparent"
                            className="text-white whitespace-nowrap overflow-hidden"
                          />
                          {dropdown.grandChildren && (
                            <>
                              {" "}
                              <Icon
                                IconComponent={IoChevronDownSharp}
                                size="14px"
                                color={`${
                                  activeSubDropdown === dropdown.key
                                    ? "white"
                                    : "black"
                                }`}
                                className="ml-2 md:hidden"
                              />
                              <Icon
                                IconComponent={FaChevronRight}
                                size="14px"
                                color={`${
                                  activeSubDropdown === dropdown.key
                                    ? "white"
                                    : "black"
                                }`}
                                className="ml-2 hidden md:block"
                              />
                            </>
                          )}
                        </button>
                        <div className="flex  flex-col">
                          {dropdown.grandChildren &&
                            dropdown.key === activeSubDropdown &&
                            dropdown.grandChildren.map((subDropDown) => (
                              <Flex
                                direction="col"
                                justify="start"
                                align="start"
                                className={`cursor-pointer  rounded-md min-w-52  ml-2 md:flex-row shadow−[0px 0px 6px 0px]   text-black ${subDropDown.key}`}
                                key={subDropDown.key}>
                                <button
                                  className={`${
                                    subDropDown.key ===
                                      "supportingDepartment" ||
                                    subDropDown.key === "library"
                                      ? "md:rounded-b-lg"
                                      : ""
                                  } flex flex-row p-2 justify-between w-[260px] items-center  h-8 `}
                                  style={{
                                    backgroundColor: `${
                                      activeGrandchildrenDropdown ===
                                      subDropDown.key
                                        ? "rgba(41, 74, 116)"
                                        : "white"
                                    }`,
                                    boxShadow: `${
                                      activeGrandchildrenDropdown ===
                                      subDropDown.key
                                        ? "0px 0px 6px 0px"
                                        : "0px 2px 4px 0px rgba(41, 74, 112, 1)"
                                    }`,
                                  }}
                                  onClick={() => {
                                    setActiveGrandchildrenDropDown(
                                      activeGrandchildrenDropdown ===
                                        subDropDown.key
                                        ? null
                                        : subDropDown.key
                                    );
                                    subDropDown.route &&
                                      Navigate(subDropDown.route);
                                    subDropDown.route && toggleRedirected();
                                  }}>
                                  <Text
                                    size="14px"
                                    color={`${
                                      activeGrandchildrenDropdown ===
                                      subDropDown.key
                                        ? "white"
                                        : "black"
                                    }`}
                                    content={subDropDown.label}
                                    weight="400"
                                    backgroundColor="transparent"
                                    className="text-white whitespace-nowrap overflow-hidden"
                                  />
                                  {subDropDown.greatGrandChildren && (
                                    <>
                                      {" "}
                                      <Icon
                                        IconComponent={IoChevronDownSharp}
                                        size="14px"
                                        color={`${
                                          subDropDown.key ===
                                          activeGrandchildrenDropdown
                                            ? "white"
                                            : "black"
                                        }`}
                                        className="ml-2 md:hidden"
                                      />
                                      <Icon
                                        IconComponent={FaChevronRight}
                                        size="14px"
                                        color={`${
                                          subDropDown.key ===
                                          activeGrandchildrenDropdown
                                            ? "white"
                                            : "black"
                                        }`}
                                        className="ml-2 hidden md:block"
                                      />
                                    </>
                                  )}
                                </button>

                                <div className="flex flex-col rounded-lg">
                                  {subDropDown.greatGrandChildren &&
                                    subDropDown.key ===
                                      activeGrandchildrenDropdown &&
                                    subDropDown.greatGrandChildren.map(
                                      (grandChildDropdown) => (
                                        <button
                                          className={`${
                                            grandChildDropdown.key ===
                                              "geography" ||
                                            grandChildDropdown.key ===
                                              "appliedEconomics"
                                              ? "md:rounded-b-lg"
                                              : ""
                                          } flex flex-row ml-2  pl-2 justify-start w-[250px] items-center bg-white h-8`}
                                          key={grandChildDropdown.key}
                                          onClick={() => {
                                            Navigate(grandChildDropdown.route);
                                            toggleRedirected();
                                          }}
                                          style={{
                                            boxShadow:
                                              "0px 2px 4px 0px rgba(41, 74, 112, 1)",
                                          }}>
                                          <Text
                                            size="14px"
                                            backgroundColor="transparent"
                                            color="black"
                                            content={grandChildDropdown.label}
                                            weight="400"
                                            className="text-white whitespace-nowrap overflow-hidden"
                                          />
                                        </button>
                                      )
                                    )}
                                </div>
                              </Flex>
                            ))}
                        </div>
                      </Flex>
                    </>
                  ))}
              </div>
            </Flex>
          ))}
        </Flex>
      </nav>
    </>
  );
};
