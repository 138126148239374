export const items = [
  {
    key: "home",
    label: "Home",
    route: "/",
  },
  {
    key: "about",
    label: "About MUE",
    children: [
      { key: "about", label: "About", route: "/about" },
      { key: "contactUs", label: "Contact Us", route: "/contact-us" },
      {
        key: "organization",
        label: "Organization",
        route: "/Organization",
      },
      { key: "masterPlan", label: "Master Plan", route: "/master-plan" },
      { key: "campus", label: "Campus", route: "/campus" },
      { key: "degreeOffered", label: "Degree Offered", route: "/degree" },
    ],
  },
  {
    key: "prospectus",
    label: "Prospectus",
    route: "/prospectus",
  },
  {
    key: "department",
    label: "Department",
    children: [
      {
        key: "academicDepartment",
        label: "Academic Department",
        grandChildren: [
          {
            key: "mainDepartment",
            label: "Main Department",
            greatGrandChildren: [
              {
                key: "commerce",
                label: "Department of Commerce",
                route: "/academic-department/main/commerce",
              },
              {
                key: "economics",
                label: "Department of Economics",
                route: "/academic-department/main/economics",
              },
              {
                key: "statistics",
                label: "Department of Statistics",
                route: "/academic-department/main/statistics",
              },
              {
                key: "managementStudies",
                label: "Department of Management Studies",
                route: "/academic-department/main/management",
              },
              {
                key: "appliedEconomics",
                label: "Department of Applied Economics",
                route: "/academic-department/main/applied-economics",
              },
            ],
          },
          {
            key: "supportingDepartment",
            label: "Supporting Department",
            greatGrandChildren: [
              {
                key: "myanmar",
                label: "Department of Myanmar",
                route: "/academic-department/supporting/myanmar",
              },
              {
                key: "english",
                label: "Department of English",
                route: "/academic-department/supporting/english",
              },
              {
                key: "mathematics",
                label: "Department of Mathematics",
                route: "/academic-department/supporting/mathematics",
              },
              {
                key: "geography",
                label: "Department of Geography",
                route: "/academic-department/supporting/geography",
              },
            ],
          },
        ],
      },
      {
        key: "administrativeDepartment",
        label: "Administrative Department",
        grandChildren: [
          {
            key: "admin",
            label: "Admin Department",
            route: "/administrative-department/admin",
          },
          {
            key: "academicAffairs",
            label: "Academic Affairs",
            route: "/administrative-department/academic-affairs",
          },
          {
            key: "finance",
            label: "Finance Department",
            route: "/administrative-department/finance",
          },
          {
            key: "ict",
            label: "ICT Department",
            route: "/administrative-department/ict",
          },

          {
            key: "engineering",
            label: "Engineering",
            route: "/administrative-department/engineering",
          },
          {
            key: "library",
            label: "Library",
            route: "/administrative-department/library",
          },
        ],
      },
    ],
  },

  {
    key: "iro",
    label: "IRO",
    children: [
      { key: "moumoa", label: "MOU/MOA", route: "/mou-moa" },
      { key: "project", label: "Project", route: "/project" },
    ],
  },
  {
    key: "research",
    label: "Research",
    children: [
      { key: "maas", label: "MAAS", route: "/research/maas" },
      { key: "urj", label: "URJ", route: "/research/urj" },
      {
        key: "international",
        label: "International",
        route: "/research/international",
      },
      { key: "phdPlan", label: "Ph.D Plan", route: "/research/ph-d" },
      { key: "master", label: "Master", route: "/research/master" },
    ],
  },
  {
    key: "library",
    label: "Library",
    route: "https://library.mue.edu.mm/",
    target: true,
  },
  {
    key: "admission",
    label: "Admission",
    children: [
      {
        key: "bachelorAdmission",
        label: "Bachelor",
        route: "/admission/bachelor",
      },
      {
        key: "masterAdmission",
        label: "Master",
        route: "/admission/master",
      },
    ],
  },
  {
    key: "activities",
    label: "Activities",
    children: [
      { key: "2024", label: "2024", route: "/activities/2024" },
      { key: "2025", label: "2025", route: "/activities/2025" },
    ],
  },
];
