import React from "react";
import AdminDeptLayout from "../../layout/AdminiDeptLayout";

const AcademicAffairs = () => {
  const positions = [
    [
      {
        name: "Daw Sandar",
        position: "Head of Department",
        department: "Department of Academic Affairs",
      },
    ],
    [
      {
        name: "Daw Yin Yin Moe",
        position: "Registrar",
        department: "Department of Academic Affairs",
      },
      {
        name: "Daw Yin Yin Maw",
        position: "Registrar",
        department: "Department of Academic Affairs",
      },
      {
        name: "Daw Me Me Thet",
        position: "Registrar",
        department: "Department of Academic Affairs",
      },
    ],
    [
      {
        name: "Daw Nwe Ni Aung",
        position: "Assistant Registrar",
        department: "Department of Academic Affairs",
      },
      {
        name: "Daw Zin Deihmang",
        position: "Assistant Registrar",
        department: "Department of Academic Affairs",
      },
    ],
    [
      {
        name: "Daw Khin Mar Swe",
        position: "Office Superintendent",
        department: "Department of Academic Affairs",
      },
    ],
    [
      {
        name: "U Zar Ni Aung",
        position: "Branch Clerk",
        department: "Department of Academic Affairs",
      },
      {
        name: "Daw Khaing Mar Htwe",
        position: "Branch Clerk",
        department: "Department of Academic Affairs",
      },
      {
        name: "Daw Wai Wai Khing",
        position: "Branch Clerk",
        department: "Department of Academic Affairs",
      },
      {
        name: "Daw Win Khaing",
        position: "Branch Clerk",
        department: "Department of Academic Affairs",
      },
      {
        name: "Daw Mar Mar Irwin",
        position: "Branch Clerk",
        department: "Department of Academic Affairs",
      },
    ],
    [
      {
        name: "Daw Phyu Zin Thaw",
        position: "Upper Division Clerk",
        department: "Department of Academic Affairs",
      },
      {
        name: "U Kyawt Maung Maung Lwin",
        position: "Upper Assistant Supervisor",
        department: "Department of Academic Affairs",
      },
      {
        name: "Daw Sandar Swe",
        position: "Senior Steno Typist",
        department: "Department of Academic Affairs",
      },
    ],
    [
      {
        name: "Daw Aye Aye Thaw",
        position: "Lower Division Clerk",
        department: "Department of Academic Affairs",
      },
      {
        name: "U Khant Zin Win",
        position: "Accountant (4)",
        department: "Department of Academic Affairs",
      },
      {
        name: "Daw Khin Hnin Yee",
        position: "Lower Division Clerk",
        department: "Department of Academic Affairs",
      },
      {
        name: "U Zwe Thiha Naing",
        position: "Lower Division Clerk",
        department: "Department of Academic Affairs",
      },
    ],
  ];

  return <AdminDeptLayout title="Academic Affairs" positions={positions} />;
};

export default AcademicAffairs;
