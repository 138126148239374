import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination, Autoplay, EffectCube } from "swiper/modules";

import {
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  eleven,
  twelve,
  thirteen,
  fourteen,
  fifteen,
  sixteen,
  seventeen,
  eighteen,
  nineteen,
  twenty,
  twentyOne,
  twentyTwo,
  twentyThree,
  twentyFour,
} from "../assets/Prospectus ( Monywa University of Economics ) +";
import { FaPlay } from "react-icons/fa6";
import { IoPlayBack, IoPlayForward } from "react-icons/io5";
import { FaPause } from "react-icons/fa";

const Prospectus = () => {
  const slides = [
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
    thirteen,
    fourteen,
    fifteen,
    sixteen,
    seventeen,
    eighteen,
    nineteen,
    twenty,
    twentyOne,
    twentyTwo,
    twentyThree,
    twentyFour,
  ];

  const swiperRef = useRef(null);
  const [autoplay, setAutoplay] = useState(true);

  const handlePause = () => {
    setAutoplay(false);
    swiperRef.current.autoplay.stop();
  };

  const handlePlay = () => {
    setAutoplay(true);
    swiperRef.current.autoplay.start();
  };

  return (
    <div className="carousel-container">
      <Swiper
        ref={swiperRef}
        modules={[Navigation, Pagination, Autoplay, EffectCube]} // Swiper modules
        effect="creative"
        spaceBetween={50}
        slidesPerView={1} // Default slides per view
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        breakpoints={{
          768: {
            slidesPerView: 2, // Show 2 slides for devices wider than 768px
          },
        }}
        onSwiper={(swiper) => {
          if (swiper) swiperRef.current = swiper;
        }}>
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <img src={slide} alt={`Slide ${index + 1}`} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="w-full text-blue-900 text-4xl items-center justify-center flex gap-10 mb-5 mt-14">
        <button onClick={() => swiperRef.current.slidePrev()}>
          <IoPlayBack />
        </button>
        <button onClick={() => (autoplay ? handlePause() : handlePlay())}>
          {autoplay ? <FaPause /> : <FaPlay />}
        </button>
        <button onClick={() => swiperRef.current.slideNext()}>
          <IoPlayForward />
        </button>
      </div>
    </div>
  );
};

export default Prospectus;
