import { Link } from "react-router-dom";
import { GiOpenBook } from "react-icons/gi";
// import "./index.css";

function ActivitiesCard({
  title,
  actiYear,
  details,
  backgroundImage,
  redirect,
}) {
  return (
    <>
      <div
        className="flex  flex-col w-full  text-left  dark-text"
        style={{
          boxShadow: "0px 1px 1px 0px #036",
          borderRadius: "26px",
          border: "1px solid rgba(0, 51, 102, 0.50)",
          // maxWidth: "400px",
          height: "auto",
          maxHeight: "360px",
        }}>
        <div
          style={{
            background: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat",
            borderRadius: "22px",
            minHeight: "200px",
            // maxWidth: "400px",
          }}></div>
        <div className="flex flex-col justify-between pb-6 pl-4 pt-2 pr-1 h-56">
          <div>
            <p
              className="text-lg font-bold line-clamp-1 max-h-9 pb-3 pt-2"
              style={{
                maxWidth: "390px",
              }}>
              {title}
            </p>
            <p
              className="text-start line-clamp-2"
              style={{
                lineHeight: "25px",
                fontSize: "14px",
                maxWidth: "390px",
              }}>
              {details}
            </p>
          </div>
          <div
            className="flex flex-row  justify-between  items-center"
            style={{ color: "rgba(0, 51, 102, 1)" }}>
            <Link
              to={`activities/${actiYear}?section=${redirect}`}
              className="w-full">
              {" "}
              <p
                className="mr-2 "
                style={{ textDecoration: "underline", fontSize: "14px" }}>
                Read More {">>"}
              </p>
            </Link>
            <GiOpenBook size={30} className="mr-2" />
          </div>
        </div>
      </div>
    </>
  );
}

export default ActivitiesCard;
