import React from "react";
import { Flex, Text } from "../ui";
import dinOrdea from "../assets/IRO/dinOrdea.png";
import maniPurlUni from "../assets/IRO/manipurUni.png";

import dhbw from "../assets/IRO/0-02-05-57c9c8c617a77eed9f22e297bddf8f63f001add62311dbd04a62c1d35f18ee46_2856d58efb7d7ada.png";
import snu from "../assets/IRO/0-02-05-8238a7c15b589f9f5b6934a65f06a4507ba3825146d609f8d6415fc4fe1420e8_970e2b9192db58ec.png";
import acec from "../assets/IRO/0-02-05-740181984ce3a45a49473e343c829a062ef60dc565214ac814a17176282aafc8_26636de90dfa429d.png";
import cespl from "../assets/IRO/0-02-05-f2a17ada95e4be4a8a833f368f926fead8188adec60aaa83f277a6670e647095_299383ef691c079c.png";
import komyra from "../assets/IRO/logo.jpg";

export default function MouMoa() {
  const partners = [
    {
      title: "Korea Myanmar Research Association (KOMYRA)",
      country: "Korea",
      date: "16.12.2021",
      image: komyra,
    },
    {
      title: "Manipur University",
      country: "India",
      date: "10.03.2022",
      image: maniPurlUni,
    },
    {
      title: "Asia China Education and Culture (ACEC) Association",
      country: "China",
      date: "29.06.2022",
      image: acec,
    },
    {
      title: "Centre For Politics and International Studies (CeSPI)",
      country: "Italy",
      date: "31.01.2023",
      image: cespl,
    },
    {
      title: "University of Oradea",
      country: "Romania",
      date: "02.02.2023",
      image: dinOrdea,
    },
    {
      title: "Baden-Wuerttemberg Cooperative State University",
      country: "Germany (DHBW)",
      date: "20.10.2023",
      image: dhbw,
    },
    {
      title: "Suncheon National University",
      country: "Korea",
      date: "28.08.2024",
      image: snu,
    },
  ];

  console.log(partners);

  return (
    <Flex
      direction="row"
      justify="center"
      className="p-10 flex-wrap w-full gap-10 md:px-10">
      {partners.map((bond, index) => (
        <>
          <div
            className={`flex items-center justify-center  flex-col w-full  lg:w-2/5 `}
            key={index}>
            <Flex
              direction="col"
              justify="center"
              align="center"
              className="p-5 border-t-8  pt-8 min-w-96 rounded-3xl max-w-[800px] border-t-yellow-300 h-auto  shadow-sm shadow-[#036] border-2 border-[#036]">
              <img
                src={bond.image}
                className="h-20 mb-3  w-auto"
                alt={bond.title}
              />
              <Text
                content={bond.title}
                size="18px"
                weight="600"
                className="mb-2 self-center place-self-center"
              />
              <div className="flex self-start place-self-start gap-3">
                <p className="font-semibold">Country</p>
                <p className="ml-3">:</p>
                <p>{bond.country}</p>
              </div>
              <div className="flex justify-start gap-3 self-start place-self-start">
                <p className="font-semibold">Date</p>
                <p className="ml-[38.5px]">:</p>
                <p>{bond.date}</p>
              </div>
            </Flex>
          </div>
        </>
      ))}
    </Flex>
  );
}
