import { Text, Flex } from "../ui";
function AdministrativeDeptProfile({ name, position, department }) {
  return (
    <>
      <Flex
        direction="col"
        justify="start"
        align="start"
        className="gap-3 border-blue-900/50 border rounded-xl shadow-lg p-8  min-w-96 border-t-8 border-t-yellow-300"
        style={{ scrollbarWidth: "none" }}>
        <div className="w-full flex justify-center">
        <Text
          color="black"
          size="20px"
          weight="700"
          className=" text-center mb-5 whitespace-nowrap"
          content={name}
        /></div>
        <div className="flex items-start justify-start">
          <p>Position</p>
          <p className="ml-8 mr-3">:</p>
          <Text
            color="black"
            size="16px"
            weight="400"
            className="text-justify whitespace-nowrap"
            content={position}
          />
        </div>
        <div className="flex  items-start justify-start">
          <p>Department</p>
          <p className="ml-1 mr-3">:</p>
          <Text
            color="black"
            size="16px"
            weight="400"
            className="text-justify whitespace-nowrap"
            content={department}
          />
        </div>
      </Flex>
    </>
  );
}

export default AdministrativeDeptProfile;
