import React from "react";
import AdminDeptLayout from "../../layout/AdminiDeptLayout";

const Engineering = () => {
  const positions = [
    [
      {
        name: "Daw Khat Khat Oo",
        position: "Head",
        department: "Department of Engineering",
      },
    ],
    [
      {
        name: "Daw Myint Myint Aye",
        position: "Excutive Engineer",
        department: "Department of Engineering",
      },
      {
        name: "Daw Zarni Win",
        position: "Excutive Engineer",
        department: "Department of Engineering",
      },
      
    ],[{
      name: "U Thet Khaing Tun",
      position: "Senior Assistant Enginner II",
      department: "Department of Engineering",
    },],[{
      name: "U Myint Bo",
      position: "Mechanic Enginner ",
      department: "Department of Engineering",
    },]
  ];

  return <AdminDeptLayout title="Engineering" positions={positions} />;
};

export default Engineering;
