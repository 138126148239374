import React from "react";
import AdminDeptLayout from "../../layout/AdminiDeptLayout";

const AdminDepartment = () => {
  const positions = [
    [
      {
        name: "Daw Khat Khat Oo",
        position: "Head",
        department: "Department of Admin & Finance",
      },
    ],
    [
      {
        name: "Daw Phyu Pyar",
        position: "Head of Division ",
        department: "Department of Admin",
      },
    ],
    [
      {
        name: "Daw Than Than Sint",
        position: "Office Superintendent ",
        department: "Department of Admin",
      },
    ],
    [
      {
        name: "Daw Khin Myat Kyu",
        position: "Branch Clerk ",
        department: "Department of Admin",
      },
      {
        name: "Daw Thin Thin Aye",
        position: "Branch Clerk ",
        department: "Department of Admin",
      },
    ],
    [
      {
        name: "Daw Tin Sandar Win",
        position: "Senior Clerk ",
        department: "Department of Admin",
      },
      {
        name: "Daw Khin Thuzar Win",
        position: "Senior Clerk ",
        department: "Department of Admin",
      },
      {
        name: "U Kaung Myat Aung",
        position: "Senior Clerk ",
        department: "Department of Admin",
      },
    ],
    [
      {
        name: "U Myo Min Thu",
        position: "Senior Typist ",
        department: "Department of Admin",
      },
    ],
    [
      {
        name: "Daw Pan Wai Oo",
        position: "Lower Division Clerk ",
        department: "Department of Admin",
      },
      {
        name: "Daw Thein Thein Htay",
        position: "Lower Division Clerk ",
        department: "Department of Admin",
      },
      {
        name: "Daw Myint Myint Thu",
        position: "Lower Division Clerk ",
        department: "Department of Admin",
      },
      {
        name: "Daw Aye Aye Moh",
        position: "Lower Division Clerk ",
        department: "Department of Admin",
      },
      {
        name: "Daw Aye Thiri Mon",
        position: "Lower Division Clerk ",
        department: "Department of Admin",
      },
    ],
  ];

  return <AdminDeptLayout title="Admin" positions={positions} />;
};

export default AdminDepartment;
